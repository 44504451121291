import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pm-success-password-reset',
  templateUrl: './success-password-reset.component.html',
  styleUrls: ['./success-password-reset.component.css']
})
export class SuccessPasswordResetComponent implements OnInit {
  loading = false;
  constructor(private router : Router) { }

  ngOnInit() {
  }

  OnSubmit()
  {
    this.loading = true;
    setTimeout(()=>{
      this.router.navigate(['/login']);
    },1000); 
  }

}
