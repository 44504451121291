export const applications = [
    {
      'CategoryID': 1,
      'CategoryName': 'Beverages',
      'Description': 'Soft drinks, coffees, teas, beers, and ales'
    },
    {
      'CategoryID': 2,
      'CategoryName': 'Condiments',
      'Description': 'Sweet and savory sauces, relishes, spreads, and seasonings'
    },
    {
      'CategoryID': 6,
      'CategoryName': 'Meat/Poultry',
      'Description': 'Prepared meats'
    },
    {
      'CategoryID': 7,
      'CategoryName': 'Produce',
      'Description': 'Dried fruit and bean curd'
    },
    {
      'CategoryID': 8,
      'CategoryName': 'Seafood',
      'Description': 'Seaweed and fish'
    }
  ];
  