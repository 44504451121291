import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pm-activation-failure',
  templateUrl: './activation-failure.component.html',
  styleUrls: ['./activation-failure.component.css']
})
export class ActivationFailureComponent implements OnInit {
  loading = false;
  constructor(private router : Router) { }

  ngOnInit() {
  }
  OnSubmit()
  {
    this.loading = true;
      setTimeout(()=>{
       this.router.navigate(['/login']);
      },1000); 
  }
}
