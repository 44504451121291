import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, zip } from 'rxjs';
import { UserService } from '../shared/user.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

const itemIndex = (item: any, data: any[]): number => {
    for (let idx = 0; idx < data.length; idx++) {
        if (data[idx].license_permission_key === item.license_permission_key) {
            return idx;
        }
    }

    return -1;
};

const cloneData = (data: any[]) => data.map(item => Object.assign({}, item));

@Injectable({
    providedIn: 'root'
})
export class EditActiveActivationsService extends BehaviorSubject<any[]> {
    private data: any[] = [];
    errors: any;

    constructor(private http: HttpClient, private userService: UserService,
         private toastr: ToastrService, public translate: TranslateService) {
        super([]);
    }

    public read(licenseNumber: string, forUser: string) 
    {
        this.userService.GetActiveActivationsPerLicenseAndUser(licenseNumber, forUser).subscribe((data: any) => {   
            this.data = data;
            super.next(data);    
      });
    }
}