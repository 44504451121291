import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { ManageLicenseComponent } from '../manage-license.component';
import { EditActiveActivationsService } from '../editActiveActivationsService.service';
import { Observable } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pm-kendo-grid-view-activations',
  templateUrl: './kendo-grid-view-activations.component.html',
  styleUrls: ['./kendo-grid-view-activations.component.css']
})
export class KendoGridViewActivationsComponent implements OnInit {
  licenseNumber: string;
  forUser: string;
  public view: Observable<GridDataResult>;
  public gridState: State = {
      sort: [],
      skip: 0,
      take: 5
  };

  constructor(private userService: UserService, private data: ManageLicenseComponent,
    public editService: EditActiveActivationsService) {}

  public ngOnInit(): void 
  {
    this.data.currentMessage.subscribe(licenseNumber => this.licenseNumber = licenseNumber);
    this.data.currentMessage2.subscribe(forUser => this.forUser = forUser);
    this.view = this.editService.pipe(map(data => process(data, this.gridState)));
    this.editService.read(this.licenseNumber,this.forUser);
    
  }

  public onStateChange(state: State) 
  {
    this.data.currentMessage.subscribe(licenseNumber => this.licenseNumber = licenseNumber);
    this.data.currentMessage2.subscribe(forUser => this.forUser = forUser);
    this.gridState = state;
    this.editService.read(this.licenseNumber,this.forUser);
  }

}