import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pm-confirmation-request-registration',
  templateUrl: './confirmation-request-registration.component.html',
  styleUrls: ['./confirmation-request-registration.component.css']
})
export class ConfirmationRequestRegistrationComponent implements OnInit {
  loading = false;

  constructor(private router : Router) { }

  ngOnInit() {
  }

  OnSubmit()
  {
    this.loading = true;

    setTimeout(()=>{
      this.router.navigate(['/login']);
    },1000); 

  }
}
