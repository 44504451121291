
import { Injectable } from '@angular/core';

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';



@Injectable()

export class AuthGuard implements CanActivate {

  constructor(private router : Router, private cookie: CookieService){}

  canActivate(

    next: ActivatedRouteSnapshot,

    state: RouterStateSnapshot):  boolean {

      if (this.getCurrentToken() != null)
      return true;

      this.router.navigate(['/login']);
      return false;

  }

  getCurrentToken() : string
  {
    return this.cookie.get('acc_token');
  }

}