import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { applications } from './applications';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { UserService } from '../shared/user.service';

const createFormGroup = dataItem => new FormGroup({
    'license_key': new FormControl(dataItem.license_key),
    'application_key': new FormControl(dataItem.application_key, Validators.compose([Validators.required, Validators.pattern('^[0-9]{1,3}')])),
    'customer_key': new FormControl(dataItem.customer_key, Validators.compose([Validators.required, Validators.pattern('^[0-9]{1,3}')])),
    'license_number': new FormControl(dataItem.license_number, Validators.compose([Validators.required, Validators.pattern('^[0-9]{1,3}')])),
    'license_status': new FormControl(dataItem.license_status, Validators.compose([Validators.required, Validators.pattern('^[0-9]{1,3}')])),
    'license_type': new FormControl(dataItem.license_type, Validators.compose([Validators.required, Validators.pattern('^[0-9]{1,3}')])),
    'seat_number': new FormControl(dataItem.seat_number, Validators.required),
    'license_expiration_date': new FormControl(dataItem.license_expiration_date, Validators.required),
    'service_expiration_date': new FormControl(dataItem.service_expiration_date, Validators.required),
    'activation_limit': new FormControl(dataItem.activation_limit, Validators.required),
    'license_notes': new FormControl(dataItem.license_notes, Validators.required),
    'anchor_crm': new FormControl(dataItem.anchor_crm, Validators.required)
});

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
      return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'pm-license-telerik-adm',
  templateUrl: './license-telerik-adm.component.html',
  styleUrls: ['./license-telerik-adm.component.css']
})

export class LicenseTelerikAdmComponent implements OnInit {
    public gridData: GridDataResult;
    public applications: any[] = applications;
    public formGroup: FormGroup;
    private editedRowIndex: number;
    public pageSize = 15;
    public skip = 0;
    private data: Object[];
    private counter: number;
    public multiple = false;
    public allowUnsort = true;
    public sort: SortDescriptor[] = [{
      field: 'license_number',
      dir: 'asc'
    }];

    public filter: CompositeFilterDescriptor;
    private items: any[];

    constructor(private userService: UserService) {
      // this.loadItems();
    }

    public pageChange(event: PageChangeEvent): void {
      this.skip = event.skip;
      this.loadItems();
  }

  // private items: any[] = this.service.licenses();

  private loadItems(): void {
      this.gridData = {
          data: orderBy(this.items.slice(this.skip, this.skip + this.pageSize), this.sort),
          total: this.items.length
      };
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadItems();
  }

    public filterChange(filter: CompositeFilterDescriptor): void {
        this.filter = filter;
        if(this.filter.filters.length == 0)
            this.gridData = {
              data: orderBy(this.items.slice(this.skip, this.skip + this.pageSize), this.sort),
              total: this.items.length
            };
         else
         {
            this.gridData = {
              data: filterBy(this.items, filter),
              total: this.items.length
            };
         }
    }

    public ngOnInit() {
      this.userService.getLicenses().subscribe((data: any) => {       
        this.items =  data; 
        this.counter = this.items.length;
        this.loadItems();
       });

    }
    

    public application(id: number): any {
        return this.applications.find(x => x.application_key === id);///////////////////////////////////////////
    }

    public addHandler({ sender }) {
        this.closeEditor(sender);

        this.formGroup = createFormGroup({
            'application_key': 1,
            'customer_key': 1,
            'license_number': '',
            'license_status': 1,
            'license_type': 1,
            'seat_number': '',
            'license_expiration_date': '',
            'service_expiration_date': '',
            'activation_limit': '',
            'license_notes': '',
            'anchor_crm': '',
        });

        sender.addRow(this.formGroup);
    }

    public editHandler({ sender, rowIndex, dataItem }) {
        this.closeEditor(sender);

        this.formGroup = createFormGroup(dataItem);

        this.editedRowIndex = rowIndex;

        sender.editRow(rowIndex, this.formGroup);
    }

    public cancelHandler({ sender, rowIndex }) {
        this.closeEditor(sender, rowIndex);
    }

    public saveHandler({ sender, rowIndex, formGroup, isNew }): void {
        const license = formGroup.value;

        this.save(license, isNew);

        sender.closeRow(rowIndex);
        this.loadItems();
    }

    public removeHandler({ dataItem }): void {
        this.remove(dataItem);
        this.loadItems();
    }

    private closeEditor(grid, rowIndex = this.editedRowIndex) {
        grid.closeRow(rowIndex);
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
    }

    ///////

    public remove(license: any): void {
      const index = this.items.findIndex(({ license_key }) => license_key === license.license_key);
      this.data.splice(index, 1);
  }

  public save(license: any, isNew: boolean): void {
      if (isNew) {
          license.license_key = this.counter++;
          this.items.splice(0, 0, license);
      } else {
          Object.assign(
              this.items.find(({ license_key }) => license_key === license.license_key),
              license
          );
      }
  }

    /////
}
