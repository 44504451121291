
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProductsService } from './license.service';
import { categories } from './applications';

import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';

const createFormGroup = dataItem => new FormGroup({
    'ProductID': new FormControl(dataItem.ProductID),
    'ProductName': new FormControl(dataItem.ProductName, Validators.required),
    'UnitPrice': new FormControl(dataItem.UnitPrice),
    'UnitsInStock': new FormControl(dataItem.UnitsInStock, Validators.compose([Validators.required, Validators.pattern('^[0-9]{1,3}')])),
    'CategoryID': new FormControl(dataItem.CategoryID, Validators.required)
});

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
      return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'pm-license-kendo',
  templateUrl: './license-kendo.component.html',
  styleUrls: ['./license-kendo.component.css']
})

export class LicenseKendoComponent implements OnInit {
    public gridData: GridDataResult;
    public categories: any[] = categories;
    public formGroup: FormGroup;
    private editedRowIndex: number;
    public pageSize = 4;
    public skip = 0;
    private data: Object[];

    public multiple = false;
    public allowUnsort = true;
    public sort: SortDescriptor[] = [{
      field: 'ProductName',
      dir: 'asc'
    }];

    public filter: CompositeFilterDescriptor;

    constructor(private service: ProductsService) {
      this.loadItems();
    }

    public pageChange(event: PageChangeEvent): void {
      this.skip = event.skip;
      this.loadItems();
  }

  private items: any[] = this.service.products();

  private loadItems(): void {
      this.gridData = {
          data: orderBy(this.items.slice(this.skip, this.skip + this.pageSize), this.sort),
          total: this.items.length
      };
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadItems();
  }

    public filterChange(filter: CompositeFilterDescriptor): void {
        this.filter = filter;
        if(this.filter.filters.length == 0)
            this.gridData = {
              data: orderBy(this.service.products().slice(this.skip, this.skip + this.pageSize), this.sort),
              total: this.items.length
            };
         else
         {
            this.gridData = {
              data: filterBy(this.service.products(), filter),
              total: this.items.length
            };
         }
    }

  // private loadProducts(): void {
  //   this.gridData = {
  //       data: orderBy(this.service.products(), this.sort),
  //       total: this.service.products().length
  //   };
  // }

    public ngOnInit(): void {
        //this.gridData = this.service.products();
    }

    public category(id: number): any {
        return this.categories.find(x => x.CategoryID === id);
    }

    public addHandler({ sender }) {
        this.closeEditor(sender);

        this.formGroup = createFormGroup({
            'ProductName': '',
            'UnitPrice': 0,
            'UnitsInStock': '',
            'CategoryID': 1
        });

        sender.addRow(this.formGroup);
    }

    public editHandler({ sender, rowIndex, dataItem }) {
        this.closeEditor(sender);

        this.formGroup = createFormGroup(dataItem);

        this.editedRowIndex = rowIndex;

        sender.editRow(rowIndex, this.formGroup);
    }

    public cancelHandler({ sender, rowIndex }) {
        this.closeEditor(sender, rowIndex);
    }

    public saveHandler({ sender, rowIndex, formGroup, isNew }): void {
        const product = formGroup.value;

        this.service.save(product, isNew);

        sender.closeRow(rowIndex);
        this.loadItems();
    }

    public removeHandler({ dataItem }): void {
        this.service.remove(dataItem);
        this.loadItems();
    }

    private closeEditor(grid, rowIndex = this.editedRowIndex) {
        grid.closeRow(rowIndex);
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
    }
}
