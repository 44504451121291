import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pm-confirmation-email-request-pass-change',
  templateUrl: './confirmation-email-request-pass-change.component.html',
  styleUrls: ['./confirmation-email-request-pass-change.component.css']
})
export class ConfirmationEmailRequestPassChangeComponent implements OnInit {
  loading = false;
  constructor(private router : Router) { }

  ngOnInit() {
  }

  OnSubmit()
  {
    this.loading = true;
      setTimeout(()=>{
       this.router.navigate(['/login']);
      },1000); 
  }
}
