export class LicensePermission {
    public license_permission_key: number;
    public license_key: number;
    public license_permission_status: number;
    public user_key = '';
    public license_permission_expiration_date= '';
    public license_permission_expiration_date_as_string = '';
    public license_number = '';
    public application_name = '';
    public application_code = '';
    public user_email = '';
    public name_of_user = '';
}