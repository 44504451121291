import { Component, ElementRef, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { UserService } from './shared/user.service';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';


@Component({

  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  title = 'app';

  constructor(private userService: UserService, private el: ElementRef, private renderer:Renderer2, public translate: TranslateService) {
    translate.addLangs(['en','pl']);
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|pl/) ? browserLang : 'pl');
  }

   ngAfterViewInit(){
    const flags = RendererStyleFlags2.DashCase | RendererStyleFlags2.Important;
    this.renderer.setStyle(this.el.nativeElement.ownerDocument.body,'background-color', '#ffffff', flags);
    }
    

  ngOnInit() {}

}