import { FormGroup, AbstractControl } from "@angular/forms";

export class PasswordValidator 
{
  static ComparePassword(
    formGroup: FormGroup
  ) {

   let valid = true;
    const control = formGroup.controls['NewPassword'].value;
    const matchingControl = formGroup.controls['ConfirmPassword'].value;
    
    if (control !== matchingControl) {
      valid = false;
    }

    if (valid) {
      return null;
    }

    return {
      ComparePassword: true
    };
  }
}