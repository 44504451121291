import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/user.service';

@Component({
  selector: 'pm-license-basic-view',
  templateUrl: './license-basic-view.component.html',
  styleUrls: ['./license-basic-view.component.css']
})
export class LicenseBasicViewComponent implements OnInit {
  gridView: any[];

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.userService.getLicenses().subscribe((data: any) => {   
    this.gridView = data;      
   });
 }
}
