import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr'
import { NgForm } from '@angular/forms';

@Component({
  selector: 'pm-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.css']
})

export class ResetPasswordFormComponent implements OnInit 
{
  loading = false;
  isvalidcode: any;
  code: string;
  email: string;

constructor(private userService : UserService, private router : Router, public translate: TranslateService, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit()
   {
    this.email = this.route.snapshot.queryParamMap.get("email");
    this.code = this.route.snapshot.queryParamMap.get("code");
    if(this.email != undefined && this.email != '' && this.code != undefined && this.code != '')
    {
        this.userService.IsPasswordChangeCodeValid(this.email, this.code).subscribe((data : any)=>{
            this.isvalidcode = data;
            if(!this.isvalidcode)
            {
              this.router.navigate(['/invalid-password-reset-code']);
            }
        },
      
        (err : HttpErrorResponse)=>{
              this.router.navigate(['/invalid-password-reset-code']);
        });
    }
    else
    {
      this.router.navigate(['/invalid-password-reset-code']);
    }

  }

  public getSavedLanguage(): string
  {
    const browserLang = this.translate.getBrowserLang();
    return browserLang.match(/en|pl/) ? browserLang : 'pl';
  }

  OnSubmit(form: NgForm, NewPassword,ConfirmPassword)
  {
    if (!form.valid) {
      this.loading = false;
      return;
    }

    this.loading = true;
    setTimeout(()=>{
        this.userService.ChangePasswordExternal(this.email, this.code,this.getSavedLanguage(),NewPassword, ConfirmPassword).subscribe((data : any)=>{
          this.router.navigate(['/success-password-reset']);
      },

      (err : HttpErrorResponse)=>{
        this.translate.get('FORGOTPASSWORDSENT.CODEFAILUREREPASSWORDCHANGEERROR').subscribe((data:any)=> {
          this.toastr.error(data);
        });
      });
  },1000); 
 }
}


// Password
// At least 8 characters in length
// Lowercase letters
// Uppercase letters
// Numbers Special characters