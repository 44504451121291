import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { UserService } from '../shared/user.service';


const createFormGroup = dataItem => new FormGroup({
    'customer_key': new FormControl(dataItem.customer_key),
    'customer_name': new FormControl(dataItem.customer_name, Validators.required),
    'customer_tax': new FormControl(dataItem.customer_tax, Validators.required),
    'customer_email': new FormControl(dataItem.customer_email, Validators.required),
    'customer_phone': new FormControl(dataItem.customer_phone, Validators.required),
    'customer_address': new FormControl(dataItem.customer_address, Validators.required),
    'customer_city': new FormControl(dataItem.customer_city, Validators.required),
    'customer_postal_code': new FormControl(dataItem.customer_postal_code, Validators.required),
    'customer_country': new FormControl(dataItem.customer_country, Validators.required),
    'user_key': new FormControl(dataItem.user_key, Validators.required),
    'user_email': new FormControl(dataItem.user_email, Validators.required),
    'anchor_crm': new FormControl(dataItem.anchor_crm, Validators.required)
});

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
      return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'pm-customer-telerik-basic',
  templateUrl: './customer-telerik-basic.component.html',
  styleUrls: ['./customer-telerik-basic.component.css']
})

export class CustomerTelerikBasicComponent implements OnInit {
    public gridData: GridDataResult;
    public formGroup: FormGroup;
    private editedRowIndex: number;
    public pageSize = 15;
    public skip = 0;
    private data: Object[];
    private counter: number;
    public multiple = false;
    public allowUnsort = true;
    public sort: SortDescriptor[] = [{
      field: 'customer_name',
      dir: 'asc'
    }];

    public filter: CompositeFilterDescriptor;
    private items: any[];

    constructor(private userService: UserService) { }

    public pageChange(event: PageChangeEvent): void {
      this.skip = event.skip;
      this.loadItems();
  }

  private loadItems(): void {
      this.gridData = {
          data: orderBy(this.items.slice(this.skip, this.skip + this.pageSize), this.sort),
          total: this.items.length
      };
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadItems();
  }

    public filterChange(filter: CompositeFilterDescriptor): void {
        this.filter = filter;
        if(this.filter.filters.length == 0)
            this.gridData = {
              data: orderBy(this.items.slice(this.skip, this.skip + this.pageSize), this.sort),
              total: this.items.length
            };
         else
         {
            this.gridData = {
              data: filterBy(this.items, filter),
              total: this.items.length
            };
         }
    }

    public ngOnInit() {

      this.userService.getCustomers().subscribe((data: any) => {       
        this.items =  data; 
        this.counter = this.items.length;
        this.loadItems();
       });

    }

    public addHandler({ sender }) {
        this.closeEditor(sender);

        this.formGroup = createFormGroup({
            'customer_key': 1,
            'customer_name': '',
            'customer_tax': '',
            'customer_email': '',
            'customer_phone': '',
            'customer_address': '',
            'customer_city': '',
            'customer_postal_code': '',
            'customer_country': '',
            'user_key': '',
            'user_email': '',
            'anchor_crm': '',
        });

        sender.addRow(this.formGroup);
    }

    public editHandler({ sender, rowIndex, dataItem }) {
        this.closeEditor(sender);

        this.formGroup = createFormGroup(dataItem);

        this.editedRowIndex = rowIndex;

        sender.editRow(rowIndex, this.formGroup);
    }

    public cancelHandler({ sender, rowIndex }) {
        this.closeEditor(sender, rowIndex);
    }

    public saveHandler({ sender, rowIndex, formGroup, isNew }): void {

        const customer = formGroup.value;

        this.save(customer, isNew);

        sender.closeRow(rowIndex);
        this.loadItems();
    }

    public removeHandler({ dataItem }): void {
        this.remove(dataItem);
        this.loadItems();
    }

    private closeEditor(grid, rowIndex = this.editedRowIndex) {
        grid.closeRow(rowIndex);
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
    }

    ///////

    public remove(customer: any): void {
      const index = this.items.findIndex(({ customer_key }) => customer_key === customer.customer_key);
      this.data.splice(index, 1);
  }

  public save(customer: any, isNew: boolean): void {
      if (isNew) {
          customer.customer_key = this.counter++;
          this.items.splice(0, 0, customer);
      } else {
          Object.assign(
              this.items.find(({ customer_key }) => customer_key === customer.customer_key),
              customer
          );
      }
  }

    /////
}

