import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../shared/user.service';
import { User } from '../shared/user.model';
import { ChangePasswordBindingModel} from  '../user-display-self/ChangePasswordBindingModel';

@Component({
  selector: 'pm-user-display-self',
  templateUrl: './user-display-self.component.html',
  styleUrls: ['./user-display-self.component.css']
})
export class UserDisplaySelfComponent implements OnInit {

  public editDataItem: ChangePasswordBindingModel;
  public isNew: boolean;

  user: User;
  errors: any;
  loading: boolean;
  constructor(private userService: UserService, private toastr: ToastrService, public translate: TranslateService) { }


  ngOnInit() {
    this.resetForm();
      this.userService.getLoggedInUser().subscribe((data: any) => {
        this.resetForm();
        this.user = {
  
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone_number: data.phone_number,
          address: data.address,
          city: data.city,
          postal_code: data.postal_code,
          country: data.country,
          password: '',
          language:'',
        }
      });
  }

  resetForm(form?: NgForm) {

    if (form != null)

      form.reset();

    this.user = {
      first_name: 'NULL',
      last_name: 'NULL',
      email: 'NULL',
      phone_number: 'NULL',
      address: 'NULL',
      city: 'NULL',
      postal_code: 'NULL',
      country: 'NULL',
      password: 'NULL',
      language:'NULL',
    }
  }

  OnSubmit(form: NgForm) 
  {
    this.loading = true;
    setTimeout(()=>{
    this.userService.getMyProfile('email').subscribe((userdata: any) => {
       this.userService.updateUserProfile(form.value, userdata)
                 .subscribe((data: any) => {
                  this.loading = false;
                  this.translate.get('PROFIL.CODESUCCESFULPROFILEUPDATE').subscribe((data:any)=> {
                    this.toastr.success(data);
                  });
        }, (err) =>   this.translate.get('PROFIL.CODEFAILUREPROFILEERROR').subscribe((data:any)=> {
          this.toastr.error(data);
          this.loading = false;
        }));
    });
  },1000); 
  }

  public getSavedLanguage(): string
  {
    const browserLang = this.translate.getBrowserLang();
    return browserLang.match(/en|pl/) ? browserLang : 'pl';
  }

  // Change Password Section

  public addHandler()
  {
    this.editDataItem = new ChangePasswordBindingModel();
    this.isNew = true;
}

public cancelHandler()
{
  this.editDataItem = undefined;
}

public saveHandler(changePasswordBindingModel: ChangePasswordBindingModel) 
{
  this.save(changePasswordBindingModel, this.isNew);
  this.editDataItem = undefined;
}

private save(data: any, isNew?: boolean) 
{
  this.errors = '';
    this.userService.changePasswordInternal(data)
    .subscribe(
        result => {
            // Handle result
            this.translate.get('PROFIL.CODESUCCESFULPASSWORDINTERNALUPDATE').subscribe((data:any)=> {
              this.toastr.success(data);
            });
        },
        error => {
            if(error.error.Message == "The request is invalid.")
            {
              let validationErrorDictionary = error.error.ModelState;
              for (var fieldName in validationErrorDictionary) {
                  if (validationErrorDictionary.hasOwnProperty(fieldName)) {
                  this.errors = (validationErrorDictionary[fieldName][0]);
                  }
              }
            }else{
                  this.errors = error.error.Message;
            }

            if(this.errors == undefined || this.errors == '')
            {
              this.GetTranslationForErrorPasswordChangeInternal(error.error.Message);
            }
            else
            {
              this.GetTranslationForErrorPasswordChangeInternal(this.errors);
            }
        }
  );
}

GetTranslationForErrorPasswordChangeInternal(error: any) 
{
  var languageUsed = this.getSavedLanguage();
  if(languageUsed == "pl" && error == "The new password and confirmation password do not match.")
  {
    this.translate.get('PROFIL.CODEERRORPASSWORDINTERNALUPDATE1').subscribe((data:any)=> {
      this.toastr.error(data);
    });
  }
  else if(languageUsed == "pl" && error == "Unable To Change Password- invalid password")
  {
    this.translate.get('PROFIL.CODEERRORPASSWORDINTERNALUPDATE2').subscribe((data:any)=> {
      this.toastr.error(data);
    });
  }
  else
  {
    this.toastr.error(error);;
  }
 }
}
