import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { ManageLicenseComponent } from '../manage-license.component';
import { EditActiveActivationsForAllLicensesService } from '../editActiveActivationsForAllLicensesService.service';
import { Observable } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pm-kendo-grid-view-activations-per-licenses',
  templateUrl: './kendo-grid-view-activations-per-licenses.component.html',
  styleUrls: ['./kendo-grid-view-activations-per-licenses.component.css']
})
export class KendoGridViewActivationsPerLicensesComponent implements OnInit {
  public view: Observable<GridDataResult>;
  public gridState: State = {
      sort: [],
      skip: 0,
      take: 5
  };

  constructor(private userService: UserService, private data: ManageLicenseComponent,
    public editService: EditActiveActivationsForAllLicensesService) {}

  public ngOnInit(): void 
  {
    this.view = this.editService.pipe(map(data => process(data, this.gridState)));
    this.editService.read();
    
  }

  public onStateChange(state: State) 
  {
    this.gridState = state;
    //this.view = this.editService.pipe(map(data => process(data, this.gridState)));
    this.editService.read();
  }

}