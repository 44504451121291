import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'pm-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.css']
})
export class ActivateAccountComponent implements OnInit {
  loading = false;
  code: string;
  email: string;

constructor(private userService : UserService, private router : Router, public translate: TranslateService, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit()
  {
    this.loading = true;
    this.email = this.route.snapshot.queryParamMap.get("email");
    this.code = this.route.snapshot.queryParamMap.get("code");
    if(this.email != undefined && this.email != '' && this.code != undefined && this.code != '')
    {
        setTimeout(()=>{
            this.userService.ActivateUserAccount(this.email, this.code).subscribe((data : any)=>{
              this.loading = false;
              this.router.navigate(['/activation-success']);
          },

          (err : HttpErrorResponse)=>{
            this.translate.get('ACCOUNTACTIVATION.CODEFAILUREACCOUNTACTIVATION').subscribe((data:any)=> {
              this.toastr.error(data);
              this.loading = false;
              this.router.navigate(['/activation-failure']);
            });
          });
      },1000); 
    }
  }
}

