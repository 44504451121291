import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { LicensePermission } from '../LicensePermissionModel';
import { UserService } from '../../shared/user.service';
import { LicenseDropDownList } from '../../shared/license.dropdownlist.model';


import { LOCALE_ID, Inject } from '@angular/core';
import { IntlService, CldrIntlService } from '@progress/kendo-angular-intl';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'kendo-grid-edit-license-permission-form',
  templateUrl: './grid-edit-license-permission-form.component.html',
  styles: [
    'input[type=text] { width: 100%; }'
  ],
})

export class GridEditLicensePermissionFormComponent {
  public licenseNumbers: Array<LicenseDropDownList> = [];
  public value: Date = new Date(2000, 2, 10);
  public max: Date = new Date(2200, 1, 1);

  locale = 'en-US';

  public active = false;
  public editForm: FormGroup = new FormGroup({
      'license_permission_key': new FormControl(),
      'license_key': new FormControl('', Validators.required),
      'user_email': new FormControl('', Validators.required),
      'license_permission_expiration_date_as_string': new FormControl('', Validators.required),
  });
  
  public email_source: Array<string> = [];
  public data_email: Array<string>;

  constructor(@Inject(LOCALE_ID) public localeId: string, 
  public intlService: IntlService, private userService: UserService, public translate: TranslateService) 
  {
    var languageUsed = this.getSavedLanguage();
           
    if(languageUsed == "pl")
    {
      this.locale = 'pl-PL';
    }

    (<CldrIntlService>this.intlService).localeId = this.locale;
  }

  public getSavedLanguage(): string
  {
    const browserLang = this.translate.getBrowserLang();
    return browserLang.match(/en|pl/) ? browserLang : 'pl';
  }

  public ngOnInit() {   
    this.userService.getLicensesForCustomersOnly().subscribe((data: any) => {
      this.licenseNumbers =  data; 
     });
  }

  handleFilter(value) 
  {
   if(value != undefined)
   {
      var number_of_characters = value.length;
      var index_of_at_sign = value.indexOf("@");
      if(number_of_characters >= 5 && index_of_at_sign != -1)
      {
        this.userService.getEmailAddresses(value).subscribe((data: any) => {
          this.email_source = data;
          this.data_email = this.email_source.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        }); 
      }
    }
  }

  @Input() public isNew = false;

  @Input() public set model(license_permission: LicensePermission) {
      this.editForm.reset(license_permission);
      this.active = license_permission !== undefined;
  }

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<LicensePermission> = new EventEmitter();

  public onSave(e): void {
      e.preventDefault();
      this.save.emit(this.editForm.value);
      this.active = false;
  }

  public onCancel(e): void {
      e.preventDefault();
      this.closeForm();
  }

  private closeForm(): void {
      this.active = false;
      this.cancel.emit();
  }
}