import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/user.service';

@Component({
  selector: 'pm-users-kendo',
  templateUrl: './users-kendo.component.html',
  styleUrls: ['./users-kendo.component.css']
})
export class UsersKendoComponent implements OnInit {

  private gridData: any;

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.userService.getUsers().subscribe((data: any) => {   
    this.gridData = data;      
   });
  }   
}
