import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';
import { EditLicensePermissionService } from './editLicensePermissionService.service';
import { LicensePermission } from './LicensePermissionModel';
import { UserService } from '../shared/user.service';


@Component({
  selector: 'pm-manage-license',
  templateUrl: './manage-license.component.html',
  styleUrls: ['./manage-license.component.css']
})
 
export class ManageLicenseComponent implements OnInit {
    public view: Observable<GridDataResult>;
    public gridState: State = {
        sort: [],
        skip: 0,
        take: 10
    };

    private messageSource = new BehaviorSubject('');
    currentMessage = this.messageSource.asObservable();
    private messageSource2 = new BehaviorSubject('');
    currentMessage2 = this.messageSource2.asObservable();
    public opened = false;
    public openedAllActivationsForLoggedUser = false;
    public openedAllSessionsForLoggedUser = false;
    public editDataItem: LicensePermission;
    public isNew: boolean;
    public removeConfirmationSubject: Subject<boolean> = new Subject<boolean>();
    public itemToRemove: any;
    TextToUser: string;
    DialogTitle: any;

    constructor(public editService: EditLicensePermissionService, private userService: UserService) {
        this.removeConfirmation = this.removeConfirmation.bind(this);
    }

    public ngOnInit(): void {
        this.view = this.editService.pipe(map(data => process(data, this.gridState)));
        this.editService.read();
    }

    public onStateChange(state: State) {
        this.gridState = state;
        this.editService.read();
    }

    public addHandler() {
        this.editDataItem = new LicensePermission();
        this.isNew = true;
    }

    public cancelHandler() {
        this.editDataItem = undefined;
    }

    public saveHandler(product: LicensePermission) {
        this.editService.save(product, this.isNew);

        this.editDataItem = undefined;
    }

    public removeHandler({dataItem}) 
    {
        this.removeConfirmation(dataItem);
    }

    public confirmRemove(shouldRemove: boolean): void 
    {
        this.removeConfirmationSubject.next(shouldRemove);
        if (shouldRemove== true)
        {  
           this.editService.remove(this.itemToRemove);
        }
            
        this.itemToRemove = null;
    }

    public removeConfirmation(dataItem): Subject<boolean>
    {
        this.itemToRemove = dataItem;
        return this.removeConfirmationSubject;
    }

    public showActivationHandler(dataItem) 
    {
        this.TextToUser = dataItem.license_number + " - " + dataItem.user_email;
        this.messageSource = new BehaviorSubject(dataItem.license_number);
        this.currentMessage = this.messageSource.asObservable();
        this.messageSource2 = new BehaviorSubject(dataItem.user_email);
        this.currentMessage2 = this.messageSource2.asObservable();
        this.opened = true;
    }

    public close(status) 
    {
        this.opened = false;
    }

    public showAllActivationsForAllLicensesHandler() 
    {
        this.DialogTitle = '';
        this.openedAllActivationsForLoggedUser = true;
    }

    public closeActivations(status) 
    {
        this.openedAllActivationsForLoggedUser = false;
    }

    public showAllSessionsForAllLicensesHandler() 
    {
        this.DialogTitle = '';
        this.openedAllSessionsForLoggedUser = true;
    }

    public closeSessions(status) 
    {
        this.openedAllSessionsForLoggedUser = false;
    }
}