import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from '../shared/user.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'pm-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent {

  userMenuItemList: any;
  userMenuItemListTmp: any;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, private userService: UserService, public translate: TranslateService, private cookie: CookieService) {}
 
   loginStatus$ : Observable<boolean>;
   UserName$ : Observable<string>;

  ngOnInit() 
  {
   this.GetMenuItems();
   this.GetCurrentUserEmail();   
  }

  Logout() 
  {
    this.cookie.delete('acc_token');
    this.cookie.delete('mps_token');
    localStorage.removeItem('umn');
    localStorage.removeItem('uel');
    this.router.navigate(['/login']);
    this.cookie.deleteAll('/', 'localhost');   
    this.cookie.deleteAll('/', '.gacad.pl');  
  }

  GetCurrentUserEmail()
  {
  
    var current_user_email = localStorage.getItem('uel');
    if(current_user_email != null)
    {
      localStorage.setItem('uel', current_user_email);
      this.UserName$ = new BehaviorSubject<string>(localStorage.getItem('uel'));
    }
    else
    {
        this.userService.getMyProfile('email').subscribe((email: any) => {
        //Save email in local storage
        localStorage.setItem('uel', email);
        this.UserName$ = new BehaviorSubject<string>(localStorage.getItem('uel'));
        });
    }
  }

  checkLoginStatus(): boolean
  {
    var logged = JSON.parse(localStorage.getItem('umn'));

     if(logged != null)
     {
        return true;
     }
       return false;
  }

  GetMenuItems()
  {
    var current_menu_items = JSON.parse(localStorage.getItem('umn'));
    if(current_menu_items != null)
    {
      this.userMenuItemList = current_menu_items;
      this.loginStatus$ = new BehaviorSubject<boolean>(this.checkLoginStatus());
    }
    else
    {
        this.userService.getUserMenuAccess().subscribe((data: any) => {
        this.userMenuItemList = data;
        //Save menu items in local storage
        localStorage.setItem('umn',JSON.stringify(this.userMenuItemList));
        this.loginStatus$ = new BehaviorSubject<boolean>(this.checkLoginStatus());
        });
    }
  }

  GetMenuTranslated(menuitem){

    var languageUsed = this.getSavedLanguage();

    if(languageUsed == "pl" && menuitem == "Licenses")
    {
      return "Licencje";
    }

    if(languageUsed == "pl" && menuitem == "Applications")
    {
      return "Aplikacje";
    }

    if(languageUsed == "pl" && menuitem == "Users")
    {
      return "Uzytkownicy";
    }

    if(languageUsed == "pl" && menuitem == "User profile")
    {
      return "Profil użytkownika";
    }

    if(languageUsed == "pl" && menuitem == "Customer profile")
    {
      return "Profil klienta";
    }

    if(languageUsed == "pl" && menuitem == "Manage licenses")
    {
      return "Zarzadzaj licencjami";
    }

     return menuitem;
  }


  public getSavedLanguage(): string
  {
    const browserLang = this.translate.getBrowserLang();
    return browserLang.match(/en|pl/) ? browserLang : 'pl';
  }

}


