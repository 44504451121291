import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'
import { User } from '../../shared/user.model';
import { UserService } from '../../shared/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})

export class SignUpComponent implements OnInit {
  user: User;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  loading = false;
  luke:string;

  constructor(private userService: UserService, private toastr: ToastrService, public translate: TranslateService, private router : Router) { }

  ngOnInit() {
    this.resetForm();
  }

// Password
// At least 8 characters in length
// Lowercase letters
// Uppercase letters
// Numbers Special characters

  resetForm(form?: NgForm) {

    if (form != null)
      form.reset();

    this.user = {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      address: '',
      city: '',
      postal_code: '',
      country: '',
      password: '',
      language: ''
    }
  }

  OnSubmit(form: NgForm) {
    if (!form.valid) {
      this.loading = false;
      return;
    }
    this.loading = true;
    setTimeout(()=>{
    form.value.language = this.getSavedLanguage();
    this.userService.requestUserRegistration(form.value)

      .subscribe((data: any) => {
        if (data.Succeeded == true) 
        {
          this.resetForm(form);
          this.loading = false;
          this.translate.get('REGISTER.CODESUCCESFULREGISTRATION').subscribe((data:any)=> {
            this.toastr.success(data);
            this.router.navigate(['/request-register']);
          });
        }
        else
          this.loading = false;
          this.toastr.error(this.GetTranslationForErrorRegistration(data.Errors[0]));

      });

    },1000);
  }

  GetTranslationForErrorRegistration(error : string){

    var languageUsed = this.getSavedLanguage();

    if(error == 'Email is required')
    {
      this.translate.get('REGISTER.CODEFAILUREREGISTRATIONERROR1').subscribe((data:any)=> {
        return this.toastr.error(data);
      });
    }

    if(error == 'Email is required | Password must have a minimum of eight characters, lowercase, uppercase letters, at least one special character and one number')
    {
      this.translate.get('REGISTER.CODEFAILUREREGISTRATIONERROR2').subscribe((data:any)=> {
        return this.toastr.error(data);
      });
    }

    if(error == 'Password must have a minimum of eight characters, lowercase, uppercase letters, at least one special character and one number')
    {
      this.translate.get('REGISTER.CODEFAILUREREGISTRATIONERROR3').subscribe((data:any)=> {
        return this.toastr.error(data);
      });
    }

    if(languageUsed == "pl" && error.startsWith(' Unable'))
    {
      return " Nie mozna dodac uzytkownika poniewaz  - adres email " + this.user.email + " jest juz zarejestrowany.";
    }

    return error;
  }

  public getSavedLanguage(): string
  {
    const browserLang = this.translate.getBrowserLang();
    return browserLang.match(/en|pl/) ? browserLang : 'pl';
  }
}