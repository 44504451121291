import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ChangePasswordBindingModel} from  '../../user-display-self/ChangePasswordBindingModel';
import {  PasswordValidator } from '../customvalidator.validator';

@Component({
  selector: 'pm-kendo-dialog-change-password-form',
  templateUrl: './kendo-dialog-change-password-form.component.html',
  styles: [
    'input[type=text] { width: 100%; }'
  ]
})
export class KendoDialogChangePasswordFormComponent {

   public active = false;
  //   public editForm: FormGroup = new FormGroup({
  //       'OldPassword': new FormControl('', Validators.required),
  //       'NewPassword': new FormControl('', Validators.compose([Validators.required, Validators.pattern('^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$')])),
  //       'ConfirmPassword': new FormControl('', Validators.compose([Validators.required, Validators.pattern('^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$')])), 
  //   });

    //public editForm: FormGroup = new FormGroup({
     //   'OldPassword': new FormControl('', Validators.required),
     //   'NewPassword': new FormControl('', Validators.compose([Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#_@$!%*?&])[A-Za-z\d#_@$!%*?&()]{8,}$')])),
    //    'ConfirmPassword': new FormControl('', Validators.required)
    //  }, (formGroup: FormGroup) => {
    //     return PasswordValidator.ComparePassword(formGroup);
    ///  });

      public editForm: FormGroup = new FormGroup({
        'OldPassword': new FormControl('', Validators.required),
        'NewPassword': new FormControl('', Validators.compose([Validators.required, ])),
        'ConfirmPassword': new FormControl('', Validators.required)
      }, (formGroup: FormGroup) => {
         return PasswordValidator.ComparePassword(formGroup);
      });

    @Input() public isNew = false;

    @Input() public set model(changePasswordBindingModel: ChangePasswordBindingModel) {
        this.editForm.reset(changePasswordBindingModel);

        this.active = changePasswordBindingModel !== undefined;
    }

    @Output() cancel: EventEmitter<any> = new EventEmitter();
    @Output() save: EventEmitter<ChangePasswordBindingModel> = new EventEmitter();

    public onSave(e): void 
    {
        e.preventDefault();
        this.save.emit(this.editForm.value);
        this.active = false;
    }

    public onCancel(e): void 
    {
        e.preventDefault();
        this.closeForm();
    }

    private closeForm(): void 
    {
        this.active = false;
        this.cancel.emit();
    }
}