import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({

  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']

})

export class SignInComponent implements OnInit {

  isLoginError : boolean = false;
  loading = false;

  constructor(private userService : UserService,private router : Router, private cookie: CookieService) { }

  ngOnInit() {

  }

  OnSubmit(userName,password)
  {
    if (userName == null || password == null || userName == '' || password == '') {
      this.isLoginError = true;
      this.loading = false;
      return;
    }

    this.loading = true;
    this.isLoginError = false;
    setTimeout(()=>{
     localStorage.removeItem('uel');
     localStorage.removeItem('umn');
     userName = userName.replace(/\s/g, "");
     this.userService.userAuthentication(userName,password).subscribe((data : any)=>{
     this.saveCookie('acc_token', data.access_token);
     this.router.navigate(['/profile']);

          //Get other tokens
          this.userService.userAuthenticationMaps(userName,password).subscribe((mapsdata : any)=>{
            this.saveCookie('mps_token', mapsdata.access_token);
            },
            (err : HttpErrorResponse)=>{
          });   
          
          this.userService.userAuthenticationTraining(userName,password).subscribe((mapsdata : any)=>{
            this.saveCookie('trc_token', mapsdata.access_token);
            },
            (err : HttpErrorResponse)=>{
          });   
   },

   (err : HttpErrorResponse)=>{
    localStorage.removeItem('uel');
    localStorage.removeItem('umn');
    this.isLoginError = true;
    this.loading = false;

   });

  },1000); 
 }

  saveCookie(cookieName, cookieValue)
  {
    this.cookie.set(cookieName, cookieValue, 100, '/', 'localhost');
    this.cookie.set(cookieName, cookieValue, 100, '/', '.gacad.pl');
  }
}